
  
.NavigationItem {
    
    box-sizing: border-box;
    display: flex;
    height:auto;
    align-items: center;
    text-align: center;
}


.NavigationItem a {
    color: black;
    text-decoration: none;
    /* height: 100%;
    padding: 16px, 10px; */
    /* box-sizing: border-box; */
    /* display: block; */
    /* margin-top: 25px; */
    padding: 10px;
   
}


.NavigationItem a:active,
.NavigationItem a:hover,
.NavigationItem a:focus
{
    /* background-color:	#d1d1d1; */
    border-bottom: 4px solid rgba(59, 57, 57, 0.75);
    /* color:white; */
    cursor: pointer;
    border-radius: 7px;
   
}



@media only screen and (max-width: 40em ) and (-moz-touch-enabled: 1), (pointer:coarse)  {

    .NavigationItem {
        
        display: flex;
        flex-flow: column;
        height: 100%;
        width: auto;
        align-items: center;
        padding: 2px;

      
    }
    
    .NavigationItem a {
        color: white;
        text-decoration: none;
        padding: 0px 4px;
        border-bottom: 4px solid transparent;
        box-sizing: border-box;
        display: block;
        
    }
    
    .NavigationItem a:active,
    .NavigationItem a.active,
    .NavigationItem a:hover
    {
        background-color:rgb(226, 147, 44);
        box-shadow: 2px 2px 3px black;
        color:white;
        cursor: pointer;
        border-radius: 5px;
        text-align:center;
    }

}