.Calculate{
    display: flex;
    flex-direction: row;
    color: black;
    width:100vw;
    height:100vh;
    background-color: lightseagreen;
}

.CalculationsContainer{
    
    display: flex;
    justify-content: center;
    /* margin-top: 2%; */
    flex-direction: row;
    /* width: 100%;
    height: 70%;
    margin-left: 25%;
    background-color:lightslategray; 
    color: #000000; */

}

.backgroundImage{
    /* width: 100%;
    height: 100%; */
}


.calculateTitle{
    /* width: 53%;
    height: 5%;
    margin-left: 25.1%;
    color: black;
    display: flex;
    justify-content: center;
    margin-top: 6%;
    font-size: 100px;
    text-decoration: underline; */
}

.formContainer{
    display: flex;
    flex-direction: row;
    width: 400px;
    /* height: 100%;     */
    /* padding: 10px; */
    /* width: 100%;
    height: 100%; */
    margin-top: 15%;
    background-color: white;
    height: 250px;


}

.title{
    color: red;
    background-color: red;
}
.options{
    /* display: inline-block; */
    list-style: none;
    display: flex;
    flex-direction: column;
    border-radius: 100px;
    margin-left: 200px;
    /* justify-content: space-around; */
    margin-top: 80%;

}

li{
    list-style: none;
}

.buttonStyle{
    height: 50px;
    width: 100px;
    background-color: white; 
    color: black; 
    border: 2px solid #4CAF50;
}

.buttonStyle:hover {
    background-color: #4CAF50;
    color: white;
  }

.optionsTitle{
    text-align: center;
    color: darkgreen;
}


.Stats-Container {
    display:flex;
    flex-direction: column;
    border: 5px solid rgb(148, 211, 148);;
    margin-left: 35%;
    width: 500px;
    height: 360px;
    background-color: white;
    position: fixed;
}


.Carbon-Data{
    /*background-color: rgb(148, 211, 148);*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 5px solid black;
    width: 490px;
    /* height: 250px; */
}