.Toolbar {
    height: 10%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    color: green;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    font-family: monospace;
    font-size: 15px;
    
    /* box-shadow: 2px 2px 3px white;
    opacity: 0.7; */
    

   
}


.searchBar{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: flex-start ;
    
}



.Toolbar nav {
    height: 100%;
}

.Logo {
    display: flex;
    height: 80%;
    flex-direction: row;
}


.username{
    font-size:16px;
    position: absolute;;
    right: 35px;
    top:39px;
    padding: 3px;
    height: 25px;
    box-shadow: 0px 2px 3px black;
    background-color: rgb(141, 138, 138);
    padding: 0 20px;
    box-sizing: border-box;
  
    border-radius: 0px 2px 2px;
}